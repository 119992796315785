function handleAnchorClick(event) {
    if (window.innerWidth > 760) {
        event.preventDefault(); // Prevent the default action if needed
        const li = event.currentTarget.closest('li');
        li.classList.toggle('open');
    }
}

function handleAnchorMouseover(event) {
    if (window.innerWidth > 760) {
        event.preventDefault(); // Prevent the default action if needed
        const li = event.currentTarget.closest('li');
        li.classList.add('open');
    }
}

function handleAnchorMouseleave(event) {
    if (window.innerWidth > 760) {
        event.preventDefault(); // Prevent the default action if needed
        const li = event.currentTarget.closest('li');
        li.classList.remove('open');
    }
}

function attachClickEvents() {
    document.querySelectorAll('.el-side-shortcuts li > a:not(.no-click-event)').forEach(function(anchor) {
        anchor.removeEventListener('click', handleAnchorClick); // Remove any existing event listener
        anchor.addEventListener('click', handleAnchorClick); // Attach the new event listener
        anchor.addEventListener('mouseover', handleAnchorMouseover);
        var textbox = anchor.parentNode.querySelector('.text-box');
        console.log(textbox);
        if(textbox) {
            textbox.addEventListener('mouseleave', handleAnchorMouseleave);
        }
    });
}

// Attach the click events on initial load
attachClickEvents();

// Re-attach the click events on window resize
window.addEventListener('resize', attachClickEvents);
