const mainNav = document.querySelector('header .nav-container nav.main-nav');
const navIcon = document.querySelector('header .nav-container .mob-nav a');
var icon = document.getElementById("icon");
var icon1 = document.getElementById("a");
var icon2 = document.getElementById("b");
var icon3 = document.getElementById("c");

if (mainNav) {
    const buttonBar = document.querySelector('header .nav-container .el-button-bar ');
    const langSwitcher = document.querySelector('header .nav-container .lang-switch');
    const searchFormContainer = document.querySelector('header .search .search-form-container');

    searchFormContainer.parentNode.prepend(buttonBar);
    searchFormContainer.parentNode.prepend(langSwitcher.cloneNode(true));

    /*
    navIcon.addEventListener('mouseover', event => {
        event.preventDefault();

        if (!mainNav.classList.contains('open')) {
            ElementHelper.slideDownNav(mainNav);
            mainNav.classList.add('open');
        }

        navIcon.classList.add('icon-close');
        navIcon.classList.remove('icon-menu');
    });
    */

    navIcon.addEventListener('click', event => {
        event.preventDefault();
        /*
        if (!mainNav.classList.contains('open')) {
            ElementHelper.slideDownNav(mainNav);
            mainNav.classList.add('open');
        } else {
            ElementHelper.slideUpNav(mainNav)
            mainNav.addEventListener('transitionend', () =>
                mainNav.classList.remove('open'), {
                once: true
            });
        }*/

        if (mainNav.classList.contains('open')) {
            mainNav.classList.remove('open');
            setTimeout(() => {
                mainNav.classList.add('hidden');
            }, 500);

        } else {
            mainNav.classList.remove('hidden');
            setTimeout(() => {
                mainNav.classList.add('open');
            }, 10);
        }
        icon1.classList.toggle('a');
        icon2.classList.toggle('c');
        icon3.classList.toggle('b');
    });


    const searchForm = document.querySelector('header .search .search-form-container form');
    const searchFormIcon = document.querySelector('header .search .search-form-container .icon');

    searchFormIcon.addEventListener('click', () => {
        if (searchForm.style.width === '0px' || searchForm.style.width === '') {
            searchForm.style.width = '100%';
        } else {
            searchForm.style.width = '0px';
        }
    });


    const level1LiElements = document.querySelectorAll('nav.main-nav ul.level-1 > li');
    level1LiElements.forEach(li => {
        if (li.querySelector('ul.level-2')) {
            li.classList.add('has-children');
        }
    });

    const level2LiElements = document.querySelectorAll('nav.main-nav ul.level-1 > li > ul.level-2 > li');
    level2LiElements.forEach(li => {
        if (li.querySelector('ul.level-3')) {
            li.classList.add('has-children');
        }
    });

    const childrenElements = document.querySelectorAll('nav.main-nav ul.level-1 > li.has-children');
    childrenElements.forEach(li => {
        li.addEventListener('click', event => {
            event.stopPropagation()
            if (!li.classList.contains('open')) {
                li.classList.add('open');
            } else {
                li.classList.remove('open')
            }

            //mainNav.style.height = 'auto';
        });

    });

    const subchildrenElements = document.querySelectorAll('nav.main-nav ul.level-2 > li.has-children');
    subchildrenElements.forEach(li => {
        li.addEventListener('click', event => {
            event.stopPropagation()
            if (!li.classList.contains('open')) {
                li.classList.add('open');
            } else {
                li.classList.remove('open')
            }

           // mainNav.style.height = 'auto';
        });

    });

    // Get the number of list items
    var liNum = document.querySelectorAll('nav.main-nav ul.level-3 li').length;
    var colNum = 2;
    var listItems = document.querySelectorAll('nav.main-nav ul.level-3 li');
    listItems.forEach(function(item, i) {
        if (i % Math.ceil(liNum / colNum) === 0) {
            item.classList.add('top');
        }
    });

}