// Function to check if the element is in the viewport
function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

// Function to add or remove the 'fixed' class based on the element's visibility
function checkVisibility() {
    const element = document.querySelector('.cp-apply-now-bar');
    // Temporarily remove the 'fixed' class to check the element's original position
    if (element.classList.contains('fixed')) {
        element.classList.remove('fixed');
        const inViewport = isInViewport(element);
        element.classList.add('fixed');
        if (inViewport) {
            element.classList.remove('fixed');
        }
    } else {
        if (!isInViewport(element)) {
            element.classList.add('fixed');
        }
    }
}

const barElement = document.querySelector('.cp-apply-now-bar');

if(barElement) {
    // Run the check on scroll and on load
    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('load', checkVisibility);

// Optional: Run the check on resize
    window.addEventListener('resize', checkVisibility);
}
