function setActiveAnchor() {
    const anchors = document.querySelectorAll('.sidebar ul li a');
    const currentHash = window.location.hash;

    anchors.forEach(anchor => {
        if (anchor.getAttribute('href') === currentHash) {
            anchor.classList.add('active');

        } else {
            anchor.classList.remove('active');
        }
    });
}

window.addEventListener('load', setActiveAnchor);
window.addEventListener('hashchange', setActiveAnchor);