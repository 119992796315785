
function formatTime(seconds) {
    // Round the seconds to the nearest integer
    const roundedSeconds = Math.round(seconds);

    // Calculate minutes and remaining seconds
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;

    // Format minutes and seconds as mm:ss
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
}

const audioPlayers = document.querySelectorAll('.cp-audio-player');

audioPlayers.forEach(function(player) {
    const audioSrc = player.getAttribute('data-audio');
    const playButton = player.querySelector('.player i');

    const sound = new Howl({
        src: [audioSrc],
        preload: 'metadata',
        html5: true
    });

    sound.once('load', function(){
        const durationDisplay = document.querySelector('.duration');
        durationDisplay.innerHTML = formatTime(sound.duration());
        playButton.addEventListener('click', function(event) {
            event.preventDefault();
            console.log(sound.duration())
            if (sound.playing()) {
                sound.pause();
            } else {
                sound.play();
            }
        });
    });

});
